body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Para navegadores baseados em Chromium (Chrome, Edge, Opera, etc.) */
input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  display: none !important;
}

/* Para Firefox */
input[type="password"]::-moz-reveal {
  display: none !important;
}

/* Para navegadores baseados em Edge (a partir do Edge 88+) */
input[type="password"]::-ms-reveal {
  display: none !important;
}
